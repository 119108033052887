import { HostListener, Injectable, signal } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, first, map, mergeMap, of, tap } from 'rxjs';
import { SCREEN_GRID_TYPE, GAME_SCREEN_LAYOUT } from '@libs/store/game/model';
import { CasinoStoreService } from '../store/casino-store.service';
@Injectable({
  providedIn: 'root',
})
export class CasinoConfigService {
  iphoneFullScreen = signal(false);
  @HostListener('window:resize', ['$event'])
  windowResize(event) {
    this.onResize(event);
  }
  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private casinoStoreService: CasinoStoreService,
  ) {}
  /**
   * Initializes the casino configuration service.
   * Calls onResize to set initial screen size and subscribes to route title changes.
   */
  init() {
    this.onResize();
    this.setRouteTitle().subscribe();
  }

  /**
   * Sets the page title based on the current route.
   * @returns {Observable<any>} An observable that emits route data.
   */
  private setRouteTitle() {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
      tap(event => {
        this.titleService.setTitle(event['title']);
      }),
    );
  }

  /**
   * Handles window resize events and updates the screen layout accordingly.
   * @param {Event} [event] - The resize event object (optional).
   * @returns {Observable<null>} An observable that completes after updating the layout.
   */
  private onResize(event?) {
    const innerWidth = window.innerWidth || event?.target.innerWidth;
    const desktop =
      innerWidth > 768
        ? SCREEN_GRID_TYPE.DESKTOP
        : innerWidth > 400
          ? SCREEN_GRID_TYPE.MOBILE
          : SCREEN_GRID_TYPE.MOBILE_SMALL;
    this.casinoStoreService.setDesktop(desktop);
    if (desktop === SCREEN_GRID_TYPE.DESKTOP) return of(null);
    return this.casinoStoreService.selectGameScreenLayout().pipe(
      tap((layout: GAME_SCREEN_LAYOUT) => {
        if (layout === GAME_SCREEN_LAYOUT.QUADRUPLE)
          this.casinoStoreService.setGameScreenLayout(GAME_SCREEN_LAYOUT.DOUBLE);
      }),
      first(),
    );
  }
}
